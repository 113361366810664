import React from 'react';
import { 
  TouchableOpacity, 
  Text, 
  View, 
  StyleSheet, 
  Platform 
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from './screens/homescreen.js'
import DetailsScreen from './screens/detailsscreen.js'
const Stack = createStackNavigator();

function TabButton({ title, onPress, isActive }) {
  return (
    <TouchableOpacity
      onPress={onPress} 
      style={[
        styles.button,
        {
          backgroundColor: isActive ? '#64B5F6' : '#B0BEC5',
          ...(Platform.OS === 'web' ? {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
          } : {
            ...Platform.select({
              ios: {
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.3,
                shadowRadius: 3,
              },
              android: {
                elevation: 5,
              },
            })
          })
        }
      ]}
    >
      <Text 
      style={styles.text}
      >{title}</Text>
    </TouchableOpacity>
  );
}


const styles = StyleSheet.create({
  button: {
    marginRight: 10,
    padding: 10,
    borderRadius: 5,
  },
  text: {
    color: 'white',
    fontWeight: '600',
  }
});


export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen 
          name="Home" 
          component={HomeScreen}
          options={({ navigation }) => ({
            headerTitle: '',
            headerStyle: { backgroundColor: '#512ce8' }, 
            headerLeft: () => (
              <View 
              style={{ 
                flexDirection: 'row', 
                marginLeft: 15 
                }}>
                <TabButton 
                  title="Home" 
                  onPress={() => navigation.navigate('Home')}
                  isActive={true}
                />
                <TabButton 
                  title="Details" 
                  onPress={() => navigation.navigate('Details')}
                  isActive={false}
                />
              </View>
            ),
          })}
        />
        <Stack.Screen 
          name="Details" 
          component={DetailsScreen}
          options={({ navigation }) => ({
            headerTitle: '',
            headerStyle: { backgroundColor: '#512ce8' }, 
            headerLeft: () => (
              <View style={{ flexDirection: 'row', marginLeft: 15 }}>
                <TabButton 
                  title="Home" 
                  onPress={() => navigation.navigate('Home')}
                  isActive={false}
                />
                <TabButton 
                  title="Details" 
                  onPress={() => navigation.navigate('Details')}
                  isActive={true}
                />
              </View>
            ),
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

