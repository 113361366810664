import React, { useState} from 'react';
import { 
  View, 
  Text, 
  FlatList, 
  StyleSheet,
  ScrollView,
  Dimensions,
  Platform,
  TouchableOpacity
 } from 'react-native';
import { Image } from 'expo-image';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';
import { Video } from 'expo-av';
import { ImageBackground } from 'react-native';
import * as MailComposer from 'expo-mail-composer';


// Preload images
const image1 = Asset.fromModule(require('../assets/ewf1.png')).uri;
const image2 = Asset.fromModule(require('../assets/ewf2.png')).uri;
const image3 = Asset.fromModule(require('../assets/ewf3.png')).uri;
const image4 = Asset.fromModule(require('../assets/ewf4.png')).uri;
const image5 = Asset.fromModule(require('../assets/ewf5.png')).uri;
const image6 = Asset.fromModule(require('../assets/ewf6.png')).uri;

const videoSource = require('../assets/appclip2.mp4');

// Sample data for images
const imageData = [
  { id: '1', uri: image1, title: 'Chat Screen' },
  { id: '2', uri: image2, title: 'Home Screen' },
  { id: '3', uri: image3, title: 'Events Screen' },
  { id: '4', uri: image4, title: 'Events Screen' },
  { id: '5', uri: image5, title: 'Events Screen' },
  { id: '6', uri: image6, title: 'Events Screen' },
  // ... add more image data if needed
];

function DetailsScreen() {

  const [fontLoaded, setFontLoaded] = useState(false);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);

  if (!fontLoaded) {
    return (
      <AppLoading
        startAsync={async () => {
          // Load fonts
          await Font.loadAsync({
            'Montserrat-SemiBold': require('../assets/fonts/Montserrat-SemiBold.ttf'),
            'PlayfairDisplaySC-Bold': require('../assets/fonts/PlayfairDisplaySC-Bold.ttf'),
          });

          // Preload video
          await Asset.loadAsync(videoSource);
        }}
        onFinish={() => setFontLoaded(true)}
        onError={console.warn}
      />
    );
  }


  async function sendEmail () {

    let result = await MailComposer.composeAsync({
      recipients: ["mchambers@chamware.com"],
      subject: 'Customer Service Request',
      body: 'Please let us know some of your information below.\n\nName:\n\nPhone:\n\nBest day/time to call:\n\n(Optional) Details about your app:\n\n\nWe will schedule a call with you to discuss our requirements gathering process and touchbase on your idea and how we can make it a reality!',
    });
 
  }

  const MAX_IMAGE_WIDTH = 300;  // You can change this value based on your design needs

  const handleImageLoad = (uri) => {
    console.log("running")
    Image.getSize(
      uri,
      (originalWidth, originalHeight) => {
        const scaleFactor = originalWidth / MAX_IMAGE_WIDTH;
        const newHeight = originalHeight / scaleFactor;
        setImageWidth(MAX_IMAGE_WIDTH);
        setImageHeight(newHeight);
        console.log(imageWidth, imageHeight)
      },
      (error) => {
        console.warn('Failed to get image dimensions for URI: ', uri);
        console.warn('Error details: ', error);
      }
    );
  };
  


  return (
    <ImageBackground 
      source={require('../assets/detailsbackground6.png')} 
      style={styles.backgroundImage}
      resizeMode="cover"
    >
    <View style={styles.container}>
    <ScrollView>
      <View style={styles.rowContainer}>
            
            <View
              style={{
                alignItems:Platform.OS === "web" ? "center" : "center",
                width:Platform.OS === "web" && screenWidth > 800 ? "50%" : "90%"
                }}>
            <View style={styles.textContainer}>
              <Text 
                style={styles.headerText}>Our Purpose</Text>
              
              <Text
                style={styles.subtitletext}>     Chamware is a cross-platform mobile and web app development solutions company which uses cutting edge technology ensuring security, quality, and dynamic functionality.</Text>
                <Text
                style={{
                  color:'white',
                  fontSize:18
                }}> </Text>  
                <Text
                style={styles.subtitletext}>     Contact us today for a free qoute.</Text>
                  <Text
                style={{
                  color:'white',
                  fontSize:18
                }}> </Text> 
                 <Text
                style={styles.subtitletext}>     We will schedule a call with you to discuss our requirements gathering process and touchbase on your idea and how we can make it a reality!</Text>
            </View>

            <View
            style={{
              paddingTop:35
            }}>
          <TouchableOpacity 
            onPress={sendEmail} 
            style={[
              styles.button,
            {
              ...(Platform.OS === 'web' ? {
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
              } : {
                ...Platform.select({
                  ios: {
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.3,
                    shadowRadius: 3,
                  },
                  android: {
                    elevation: 5,
                  },
                })
              })
            }
          ]}>
            <Text
              style={{
                color:"white",
                fontSize:20,
                fontWeight:'bold',
              }}>Free Quote</Text>
          </TouchableOpacity>

          </View>
            </View>
          
            <View
              style={{
                alignItems:Platform.OS === "web" ? "center" : "center",
                width:Platform.OS === "web" && screenWidth > 800 ? "50%" : "90%"
                }}>
          {Platform.OS === 'web' ? (
          <video 
          src={videoSource}
            muted
            controls
            playsinline
              style={{ 
                maxWidth: screenWidth > 800 ? 400 : '90%', height: 600,
                objectFit: 'contain' 
              }}
            autoPlay
            loop
          />
        ) : (
      <Video
            source={videoSource}
            rate={1.0}
            volume={1.0}
            isMuted={true}
            resizeMode="contain"
            shouldPlay
            isLooping
            useNativeControls // Same style as images
          />)}

          </View>
          </View>
    <View style={styles.listContainer}>
      <FlatList
        data={imageData}
        horizontal
        keyExtractor={item => item.id}
        renderItem={({ item }) => (
    <View style={styles.imageContainer}>
       <Image 
        source={{ uri: item.uri }} 
        style={[styles.image]} 
        //onLoad={() => handleImageLoad(item.uri)}
        resizeMode="contain" 
      />
    </View>
)}
        showsHorizontalScrollIndicator={true}
        
      />
      </View>
      </ScrollView>
    </View>
    </ImageBackground>
  );
}

const screenWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',  // take up full width of screen
    paddingTop: 20,
    paddingHorizontal: 10  // Use padding to ensure content doesn't touch the edges
  },
  headerText: {
    fontSize: 28,
    marginBottom: 10,
    color:"white",
    fontWeight:'bold',
    fontFamily: 'PlayfairDisplaySC-Bold'
  },
  subtitletext:{
    color:'white',
    fontSize:18,
    fontFamily: 'Montserrat-SemiBold'
  },
  imageContainer: {
    marginHorizontal: 10,
    alignItems: 'center',
    marginTop:50,
    marginBottom:100
  },
  imageTitle: {
    marginTop: 5,
    color:'white',
    fontWeight:'bold',
    fontSize:20
  },
  videoAndListContainer: {
    flexDirection: 'column',  // To put the video to the left of the FlatList
    alignItems: 'center',
    marginTop:50
  },
  backgroundImage: {
    flex: 1,
    width: null,
    height: null,
  },
  rowContainer: {
    flexDirection: screenWidth > 800 ? 'row' : 'column', 
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    marginBottom: 20,
  },

  textContainer: {
    maxWidth: 450,
    alignSelf: 'center',  // To center within its parent View
    padding: 20,
    marginRight:20,
    backgroundColor: 'rgba(200, 200, 200, 0.5)',
    borderRadius: 5,
    marginBottom: screenWidth <= 800 ? 20 : 0,
},

  image: {
    width:300,
    height: 600,
    borderRadius: 10,
    marginBottom:25
  },
  listContainer: {
    marginTop: 20
  },
  button: {
    marginRight: 10,
    marginBottom:50,
    padding: 10,
    borderRadius: 5,
    backgroundColor:"#64B5F6",
    alignSelf:"center",
    minWidth: 100,  // this ensures button is at least 100 pixels wide
},
});

export default DetailsScreen;

