import React, { useState, useEffect } from 'react';
import { 
  Platform,
  View, 
  Text, 
  ImageBackground,  
  StyleSheet,
  ScrollView
} from 'react-native';
import * as Font from 'expo-font';
import AppLoading from 'expo-app-loading';
import { TouchableOpacity } from 'react-native-gesture-handler';
import * as MailComposer from 'expo-mail-composer';
import * as SplashScreen from 'expo-splash-screen';

function HomeScreen() {

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Keep the splash screen visible while we fetch resources
        await SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          'Montserrat-SemiBold': require('../assets/fonts/Montserrat-SemiBold.ttf'),
          'PlayfairDisplaySC-Bold': require('../assets/fonts/PlayfairDisplaySC-Bold.ttf'),
        });
      } catch (e) {
        console.warn(e);
      } finally {
        setFontLoaded(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);
  

  if (!fontLoaded) {
    return null; // or some other placeholder/loading component
  }


  async function sendEmail () {

    let result = await MailComposer.composeAsync({
      recipients: ["mchambers@chamware.com"],
      subject: 'Customer Service Request',
      body: 'Please let us know some of your information below.\n\nName:\n\nPhone:\n\nBest day/time to call:\n\n(Optional) Details about your app:\n\n\nWe will schedule a call with you to discuss our requirements gathering process and touchbase on your idea and how we can make it a reality!',
    });
 
  }

  return (
    
    <ScrollView 
  style={{ flex: 1 }}
  contentContainerStyle={{ flexGrow: 1 }}>
      
    <ImageBackground 
     source={require('../assets/detailsbackground6.png')} 
     style={styles.backgroundImage}
     resizeMode="cover"
    >


      
      {Platform.OS === "web" ?
      (<View style={styles.overlayWeb}>

        <Text style={styles.titleTextWeb}>Chamware</Text> 

        <View
          style={{
            flex:1,
            flexDirection: "column",
          }}>
          <View
            style={{
              flex:1,
              flexDirection:"column",
              alignItems:"center"
            }}>
          <Text style={styles.subtitleTextWeb}>Cross-Platform Mobile & Web App</Text> 
          <Text style={styles.subtitleTextWeb}>Development Solutions</Text>
          </View>
         
        </View>
         
        <View
            style={{
              paddingTop:35
            }}>
          <TouchableOpacity 
            onPress={sendEmail} 
            style={[
              styles.button,
            {
              ...(Platform.OS === 'web' ? {
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
              } : {
                ...Platform.select({
                  ios: {
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.3,
                    shadowRadius: 3,
                  },
                  android: {
                    elevation: 5,
                  },
                })
              })
            }
          ]}>
            <Text
              style={{
                color:"white",
                fontSize:20,
                fontWeight:'bold'
              }}>Free Quote</Text>
          </TouchableOpacity>
          </View>
      </View>):<View style={styles.overlay}>

      <Text style={styles.titleText}>Chamware</Text> 

      <View
          style={{
            flex:1, 
            alignItems:"center"
          }}>
          <View
            style={{ 
              flexDirection:"column",
              alignItems:"center",
              paddingBottom:20
            }}>
          
          <Text style={styles.subtitleText}>Mobile App</Text> 
          <Text style={styles.subtitleText}>Development Solutions</Text>
          </View>
          <View>
          <TouchableOpacity 
            onPress={sendEmail}
            style={[
              styles.button,
            {
              ...(Platform.OS === 'web' ? {
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
              } : {
                ...Platform.select({
                  ios: {
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.3,
                    shadowRadius: 3,
                  },
                  android: {
                    elevation: 5,
                  },
                })
              })
            }
          ]}>
            <Text
              style={{
                color:"white",
                
              }}>Free Quote</Text>
          </TouchableOpacity>
          </View>
        </View>
      </View>}
    </ImageBackground></ScrollView>
  );
}



const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
    alignItems: 'center',
  },
  overlay: {
    alignItems:"center",
    top:135

  },
  overlayWeb: {
    alignItems:"center",
    top:165
  },
  titleTextWeb: {
    fontSize: 50, 
    color: 'white',
    marginBottom: 40,
    fontFamily: 'PlayfairDisplaySC-Bold'
  },
  titleText: {
    fontSize: 45,
    color: 'white',
    marginBottom: 30,
    fontWeight: 'bold',
    fontFamily: 'PlayfairDisplaySC-Bold'
  },
  subtitleTextWeb: {
    fontSize: 25,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    alignItems:"center",
    justifyContent:"center",
    fontFamily: 'Montserrat-SemiBold'
  },
  subtitleText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 20,
    fontFamily: 'Montserrat-SemiBold'
  },
  button: {
    marginRight: 10,
    padding: 10,
    borderRadius: 5,
    backgroundColor:"#64B5F6"
  },
});


export default HomeScreen;

